<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>会员等级名称：</p>
          <input type="text" v-model="form.VIPLVName" @keypress.enter="getData(1)">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>编号</td>
            <td>会员等级名称</td>
            <td>优惠折扣（%）</td>
            <td>优惠折扣（元）</td>
            <td>优惠取整</td>
            <td>分配门店</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.id}}</td>
            <td>{{item.VIPLVName}}</td>
            <td>{{item.VIPDiscountsPer}}%</td>
            <td>{{item.VIPDiscountsReg}}元</td>
            <td>{{countGrade(item.disGrade)}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" v-if="countPow(item.fenpeimendianId)" @click="setInfoData(item)" v-pow:2502>管理</button>
                <button type="button" class="err" v-if="countPow(item.fenpeimendianId)" @click="delOneData(item.id,item.VIPLVName)" v-pow:2503>删除</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2501>新增会员等级</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import {disGradeList} from '@/data/data'
export default {
  name: 'VIPLVSetting',
  data() {
    return {
      disGradeList,
      infoShow: false,
      title:'',
      dataList:[],
      storeList:[],
      form:{
        VIPLVName:'',
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
     this.getData(1)
     if(this.$store.state.User.jibieId==0) this.getStore()
  },
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    countGrade(grade){
      let gra=this.disGradeList.find(e=>e.id==grade)
      if(gra) return gra.name
      else return ''
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?（删除会员等级并不会影响已分配到此等级的会员(折扣)，请尽快为这些会员分配新的等级！）`,
        torf: false,
        suc:()=>{
          axios.post('/viplv/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },

    setInfoData(item){
      this.title=item.VIPLVName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/viplv/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增会员等级"
      this.MmsData.new=true
      this.infoShow=true
    },
  },
}
</script>
