<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">会员等级：</span><input type="text" v-model="form.VIPLVName"/></li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0">
                <option value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">优惠折扣（元）<i class="icon-alert-circle" title="固定优惠折扣是指单项原价优惠；&#10;例：某项目100元，某会员做了2次，优惠比例30%、固定优惠10元，实扣180-((100-10)*30%)-((100-10)*30%)=126元&#10;重要：固定优惠一旦会员余额不足以支付本次消费。固定优惠不会扣回"></i></div>
            <li class="up"><span>优惠金额：</span><input type="text" v-model="form.VIPDiscountsReg"/>元</li>
          </ul>
          <ul class="qiehuan">
            <div class="qiehuan_title">优惠折扣（%）<i class="icon-alert-circle" title="优惠比例是指优惠掉的比例；&#10;例：某项目100元，优惠比例30%，实扣100-（100*30%）=70元&#10;重要：比例优惠会员余额不足以支付本次消费时，自动比例扣回"></i></div>
            <li class="up"><span>折扣比例：</span><input type="text" v-model="form.VIPDiscountsPer"/>%</li>
          </ul>
          <ul>
            <li class="up"><span class="import">取整方式：</span>
              <select v-model="form.disGrade">
                <option :value="item.id" v-for="item in disGradeList" :key="item.id">{{item.name}}</option>
              </select>
            </li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {testStr,deepCopy} from '@/utils/util'
import {disGradeList} from '@/data/data'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      disGradeList,
      storeList:[],
      dataList:[],
      form:{
        VIPLVName:'',
        VIPDiscountsPer:'0',
        VIPDiscountsReg:'0',
        disGrade:0,
        fenpeimendianId:'0',
        fenpeimendianName:'全部门店',
        info:'',
      },
      defform:{
        VIPLVName:'',
        VIPDiscountsPer:'0',
        VIPDiscountsReg:'0',
        disGrade:0,
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.jibieId==0?"全部门店":this.$store.state.User.fenpeimendianName,
        info:'',
      }
    }
  },
  created() {this.init()},
  components: {},
  mounted() {},
  methods: {
    init(){
      if(this.$store.state.User.jibieId==0) this.getStore()
      if(this.MmsData.new){
        this.form=deepCopy(this.defform)
      }else{
        this.form=deepCopy(this.MmsData.data)
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName="全部门店"
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.VIPLVName)){
        this.$Mesg({
          content: '会员等级名称不能为空！',
          errOrSuc: 'war',
        })
        return false
      }
      axios.post(this.MmsData.new?'/viplv/add':'/viplv/edit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({
            content: this.MmsData.new?'新增会员等级成功！':'修改会员等级成功！',
          })
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({
            content: '链接失败！',
            errOrSuc: 'err',
          })
        }
      }).catch(() => {
        this.$Mesg({
          content: '链接失败！',
          errOrSuc: 'err',
        })
      })
    },
  },
}
</script>
